import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { FaMicrosoft } from "react-icons/fa";

const Curtains = () => (
  <React.Fragment>
    <Helmet>
      <title>Curtains | Melbourne | Brisbane | Birk</title>
      <meta
        property="og:title"
        content="Curtains | Melbourne | Brisbane | Birk"
      />
      <meta
        name="keywords"
        content="Roller Blinds, Roman Blinds, Curtains, Shutters, Venetians, External Range, Automation, Honeycomb Blinds"
      />
      <meta
        name="description"
        content="Birk offers range of quality Curtains. We are operated in Melbourne and Brisbane"
      />
      <meta
        property="og:description"
        content="Birk offers range of quality Curtains. We are operated in Melbourne and Brisbane"
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="http://birk.itechnotion.com/curtains" />
      <link rel="canonical" href="http://birk.itechnotion.com/curtains" />
    </Helmet>
    <Header />
    <section className="product-section">
      <div className="container-fluid all-section">
        <div className="row">
          <div className="col-md-4 col-lg-3">
            <div className="heading">
              <h2>Curtains</h2>
            </div>
            <div className="product-menu">
              <ul>
                <li className="mb-2">
                  <Link to="/roller-blinds">Roller Blinds</Link>
                </li>
                <li className="mb-2">
                  <Link to="/roman-blinds">Roman Blinds</Link>
                </li>
                <li className="mb-2">
                  <Link to="/curtains">
                    <span className="font-weight-bold">Curtains</span>
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/shutters">Shutters</Link>
                </li>
                <li className="mb-2">
                  <Link to="/venetians">Venetians</Link>
                </li>
                <li className="mb-2">
                  <Link to="/external-range">External Range</Link>
                </li>
                <li className="mb-2">
                  <Link to="/automation">Automation</Link>
                </li>
                <li className="mb-2">
                  <Link to="/honeycomb">Honeycomb</Link>
                </li>
              </ul>
              <div className="links-list sidebar-links">
                <Link to="/gallery" className="links-list-item">
                  <FaMicrosoft />
                  &nbsp; Go to gallery
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-8 col-lg-9 product-item mt-2">
            <div className="col-12 col-md-12 mb-4">
              <div className="pro-start-content">
                <div className="product-inner">
                  <p className="text-justify">
                    Curtains are perhaps the most versatile of all window
                    coverings. Yes, they’re functional – helping to filter light
                    and absorb sound, but they can be so much more. Birk custom
                    made curtains elevate a bedroom to a boudoir... a lounge to
                    a luxury... a sitting room to a suite.
                  </p>
                  <p className="text-justify">
                    Think of the showroom as a boutique, as you select from a
                    range of fashionable fabrics to compliment you and your
                    home’s unique sense of style. Be guided by our designers on
                    the perfect pleat and accessories.
                  </p>
                  <p className="text-justify">
                    Marry your material with a matching curtain rod for
                    effortless elegance. Decorative accessories, rings and
                    tiebacks can act as the final flourish in bringing a bay
                    window to life, or to lighten up a cozy kitchen corner.
                  </p>
                  <p className="text-justify mb-3">
                    Your Birk curtains can be customised in a range of
                    affordable and fashionable fabrics. Read more about our
                    Essentials Range and our Classic Range.
                  </p>
                  <p>
                    For even greater choice, elevate your interiors by choosing
                    from our Supreme Range or Premium Range.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-5 col-sm-12 mt-2 mb-4">
              <div className="d-flex flex-wrap">
                <div className="mb-2 mr-3">
                  <StaticImage
                    src="../images/curtain-icon1.png"
                    alt=""
                    className="img-fluid"
                  />
                  <p className="text-center font_style">Sheer</p>
                </div>
                <div className="mb-2 mr-3">
                  <StaticImage
                    src="../images/curtain-icon2.png"
                    alt=""
                    className="img-fluid"
                  />
                  <p className="text-center font_style">Blockout</p>
                </div>
                <div className="mb-2 mr-3">
                  <StaticImage
                    src="../images/curtain-icon3.png"
                    alt=""
                    className="img-fluid"
                  />
                  <p className="text-center font_style">Pleated</p>
                </div>
                <div className="mb-2 mr-3">
                  <StaticImage
                    src="../images/curtain-icon4.png"
                    alt=""
                    className="img-fluid"
                  />
                  <p className="text-center font_style">
                    Pinch
                    <br />
                    Pleated
                    <br />
                  </p>
                </div>
                <div className="mb-2 mr-3">
                  <StaticImage
                    src="../images/roman-icon2.png"
                    alt=""
                    className="img-fluid"
                  />
                  <p className="text-center font_style">Motorisation</p>
                </div>
              </div>
            </div>
            <div className="why-list why-list2 ml-4">
              <ul>
                <li>Ultimate light control and room darkening</li>
                <li>Insulation</li>
                <li>Sun protection</li>
                <li>Shape and frame windows</li>
              </ul>
            </div>

            <div className="col-12 col-md-12 mb-4">
              <StaticImage
                src="../images/bcurtains1.png"
                alt=""
                className="img-fluid"
              />
            </div>

            <div className="col-12 col-md-12 mb-4">
              <StaticImage
                src="../images/bcurtains2.png"
                alt=""
                className="img-fluid"
              />
            </div>

            <div className="col-12 col-md-12 mb-4">
              <StaticImage
                src="../images/bcurtains3.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </React.Fragment>
);
export default Curtains;
